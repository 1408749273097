// CSS
require('./assets/css/material-kit.css');
require('./assets/css/curbngo.css');
require('./assets/css/styles.css');

// JS
require('./assets/js/jquery.min.js');
require('./assets/js/popper.min.js');
require('./assets/js/bootstrap-material-design.min.js');
require('./assets/js/material-kit.js');
require('./assets/js/custome.js');
require('./assets/js/careers.js')
require('./assets/js/account-deletion.js')
require('./assets/js/quote.js')