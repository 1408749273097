// Submit a Quote form 
$('form.account-deletion-form').on('submit', function(event) {
    event.preventDefault();
    $('form.account-deletion-form button[type="submit"]').prop('disabled', true);
    var data = {};
    var formData = $('form.account-deletion-form').serializeArray();
    for (var i in formData) {
        if (formData[i].value)
            data[formData[i].name] = formData[i].value;
        data.captcha = window.captcha;
    }
    if (typeof fbq !== 'undefined')
        fbq('track', 'AccountDeletion');
        $.post(
            process.env.API_HOST + "/customer_account_deletion", 
            data, 
            function(data) {
                window.alert(data);
                $('form.account-deletion-form')[0].reset();
                $('form.account-deletion-form button[type="submit"]').prop('disabled', false);
            }
        ).fail(function(err, d) {
            var str = '';
            if (err.status == 422) {
                const entries = Object.entries(err.responseJSON)
                for (var i in entries) {
                    for (var j in entries[i][1]) {
                        str += entries[i][1][j] + '\n';
                    }
                }
            }
            if (str)
                window.alert(str);
            else
                window.alert("Whoops! There was a problem submitting your form.");
            $('form.account-deletion-form button[type="submit"]').prop('disabled', false);
    });
});