//Once the page is loaded, attach the "salesID" url param to the invisible salesID input field
window.addEventListener("DOMContentLoaded", function(ev) {
    if (this.window.location.pathname == "/quote.html") {
        
        const urlParams = new URLSearchParams(window.location.search);
        const salesID = urlParams.get("salesID");

        console.log(`Attaching salesID ${salesID} to input`)

        $("#salesID").val(salesID)
    }
  });